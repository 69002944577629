import React from "react";
import { Modal } from "react-bootstrap";
export default function NotificationModal({handleClose,handleOpen,modelShow}) {
  return (
    <Modal centered
    show={modelShow}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Header closeButton>
      {/* <Modal.Title>Modal title</Modal.Title> */}
    </Modal.Header>
    <Modal.Body>
     <h5 className="text-center text-danger">Please close chat connection first</h5>
    </Modal.Body>
  </Modal>
  );
}
