import axios from "axios";
import { toast } from "react-toastify";

const headerFormat = {
  "Content-Type": "multipart/form-data",
};

export const sendChatMessage = async (uuid, formData) => {
  try {

    // formData.append('uuid', uuid);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/chat/${uuid}/`,
      formData,
      {
        headers: headerFormat,
      }
    );


    return response?.data; // Return the response data for further use
  } catch (error) {
    toast.error("Error sending chat message:");
    throw error; // Re-throw the error to handle it where this method is called
  }
};

export const fetchMessage = async (formData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/chat/`,
      formData
    );
    return response; // Return the response data for further use
  } catch (error) {
    toast.error("Error fetch chat message:");
    throw error; // Re-throw the error to handle it where this method is called
  }
};

export const sendnotification = async (requestBody) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/send-notification/`,
      requestBody
    );
    return response.data; // Return the response data for further use
  } catch (error) {
    toast.error("Error notification sending failed::");
    throw error; // Re-throw the error to handle it where this method is called
  }
};

export const fetchExistLeadData = async (requestBodyForLeadCheck) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/lead-exists/`,
      requestBodyForLeadCheck
    );
    return response;
  } catch (error) {
    toast.error("Lead fetch failed:");
  }
};

export const fetchExistLeadDataScheduleAMeeting = async (requestBodyForLeadCheck) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/create_lead_and_schedule_meeting/`,
      requestBodyForLeadCheck
    );
    return response;
  } catch (error) {
    toast.error("ScheduleMetting fetch failed:");
  }
};

export const createNewLeadData = async (requestBody) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/leads-create-with-form/create/`,
      requestBody
    );
    return response;
  } catch (error) {
    toast.error("Lead creation failed. Please try again!");
    throw error; // Re-throw the error to handle it where this method is called
  }
};

export const fetchSalesRepresentative = async (uuid) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/check-sales-representative/${uuid}`
    );
    console.log(response, 'fetchSalesRepresentative');
    return response;

  } catch (error) {
    console.error("Error fetching data:", error);

  }
};

export const customerAndSalesRepresentativeUnavailableAPI = async (
  customer_id,
  SaleRepresentative_id
) => {
  const body = {
    customer_id: `${customer_id}`,
    SaleRepresentative_id: `${SaleRepresentative_id}`,
  };

  try {
    const response = await axios.post(
      // `${process.env.REACT_APP_API_URL}/check-customer-lead/`,
      `https://uat-api.veragoodrep.com/check-customer-lead/`,
      // `https://live-api.veragoodrep.com/check-customer-lead/`,

      body
    );

    if (response?.statusText === "OK") {
      // toast.success(response?.data?.message);
      toast.success("Sales agent is currently busy. They will reach out to you shortly. Thank you for your patience.");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  } catch (error) {
    toast.error("Sales representative  is not available");
    throw error; // Re-throw the error to handle it where this method is called
  }
};
