import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import usFlag from '../../src/assets/images/usa-flag.png'

export default function FormModal({
  show,
  modalType,
  handleClose,
  handleSubmit,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  address,
  setAddress,
  error,
}) {

  useEffect(() => {
    if (show === false) {
      setErrorValidation({});
    }
  }, [show])


  const [errorValidation, setErrorValidation] = useState({});
  let regChar = /^[a-zA-Z]*$/;
  let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const validateForm = () => {
    const newErrors = {};
    const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
    const cleanedPhonePattern = /\D/g;

    if (!firstName) {
      newErrors.firstName = "First Name is required!";
    } else if (firstName.length < 3) {
      newErrors.firstName = "First name should be greater than 3 characters.";
    } else if (!regChar.test(firstName)) {
      newErrors.firstName = "First Name accept only character!";
    } else if (firstName.length >= 15) {
      newErrors.firstName = "First name should be shorter than 15 characters.";
    }

    if (!lastName) {
      newErrors.lastName = "Last Name is required!";
    } else if (lastName.length < 3) {
      newErrors.lastName = "Last name should be greater than 3 characters.";
    } else if (!regChar.test(lastName)) {
      newErrors.lastName = "Last Name accept only character!";
    } else if (lastName.length >= 15) {
      newErrors.lastName = "Last name should be shorter than 15 characters.";
    }

    if (!email) {
      newErrors.email = "Email is required!"
    } else if (!regEmail.test(email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!phone) {
      newErrors.phone = "Phone is required!"
    } else if (phone.replace(cleanedPhonePattern, '').length !== 10  ) {
      newErrors.phone = "Phone number must be exactly 10 digits!";
    }

    if (!address) newErrors.address = "Address is required!";

    setErrorValidation(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const formatPhone = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (cleanedValue.length !== 10) {
      const newErrors = {};
      newErrors.phone = "Phone number must be exactly 10 digits!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else if (match) {
      const newErrors = {};
      newErrors.phone = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhone(e.target.value);
    setPhone(formattedPhone);
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    const isvalid = validateForm();
    if (isvalid) {
      handleSubmit();
      handleClose();
    }
  };

  const handleFirstNameChange = (e) => {
    const firstNameInput = e.target.value;
    if (firstNameInput === '') {
      const newErrors = {};
      newErrors.firstName = "First Name is required!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else if (regChar.test(firstNameInput)) {
      setFirstName(firstNameInput);
      const newErrors = {};
      newErrors.firstName = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else {
      const newErrors = {};
      newErrors.firstName = "Only alphabetic characters are allowed.";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  }

  const handleLastNameChange = (e) => {
    const lastNameInput = e.target.value;
    if (lastNameInput === '') {
      const newErrors = {};
      newErrors.lastName = "Last Name is required!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else if (regChar.test(lastNameInput)) {
      setLastName(lastNameInput);
      const newErrors = {};
      newErrors.lastName = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else {
      const newErrors = {};
      newErrors.lastName = "Only alphabetic characters are allowed.";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  }

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    if (emailInput === '') {
      const newErrors = {};
      newErrors.email = "Email is required!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else if (regEmail.test(emailInput)) {
      setEmail(emailInput);
      setErrorValidation((prev) => ({ ...prev, email: '' }));
    } else {
      const newErrors = {};
      newErrors.email = "Please enter a valid email address.";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  };

  const handleAddressChange = (e) => {
    const addressInput = e.target.value;
    if (addressInput?.trim() !== '') {
      const newErrors = {};
      newErrors.address = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else {
      const newErrors = {};
      newErrors.address = "Address is required!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  };


  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Talk To Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form onSubmit={handleSubmitData}>
          <Form.Group controlId="formFirstName">
            <Form.Label className="fw-bold">
              First Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              value={firstName}
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              title="Space not allowed!"
              onKeyUp={handleFirstNameChange}
            // pattern="[^ ]*"
            // onChange={(e) => handleOnChange(e)}
            // onBlur={(e) => handleOnBlur(e)}
            // maxLength={15}
            // minLength={3}
            // required
            />
            {errorValidation.firstName && <span className="error-validation">{errorValidation.firstName}</span>}
          </Form.Group>
          <Form.Group controlId="formLastName">
            <Form.Label className="fw-bold mt-3">
              Last Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              title="Space not allowed!"
              onKeyUp={handleLastNameChange}
            // pattern="[^ ]*"
            // maxLength={15}
            // minLength={3}
            // required
            />
            {errorValidation.lastName && <span className="error-validation">{errorValidation.lastName}</span>}
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label className="fw-bold mt-3">
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={50}
              onKeyUp={handleEmailChange}
            // pattern="[^ ]*"
            // required
            />
            {errorValidation.email && <span className="error-validation">{errorValidation.email}</span>}
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label className="fw-bold mt-3">Phone Number <span className="text-danger">*</span></Form.Label>
            <div className="input-group">
              <div className="input-group-prepend"> <span className="input-group-text rounded-0 p-2"> <img src={usFlag} alt="usa-flag" className="us-flag-image" /> +1
              </span> </div>
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                value={phone}
                name="phone"
                onChange={(e) => setPhone(e.target.value)}
                onKeyUp={handlePhoneChange}
                title="Required only number"
                maxLength={14}
              // pattern="\d{10}"
              // pattern="^[0-9]*$"
              // minLength={6}
              />
            </div>
            {errorValidation.phone && <span className="error-validation">{errorValidation.phone}</span>}
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label className="fw-bold mt-3">Address <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              value={address}
              name="address"
              onChange={(e) => setAddress(e.target.value)}
              onKeyUp={handleAddressChange}
              maxLength={50}
              pattern="^[^\s].*"
            />
            {errorValidation.address && <span className="error-validation">{errorValidation.address}</span>}
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3 border-0" style={{ backgroundColor: '#189ad3' }}>
            Submit
          </Button>
          {error && (<p style={{ color: 'red', marginTop: "10px" }}>{error}</p>)}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
