import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import usFlag from '../../src/assets/images/usa-flag.png'
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "react-datepicker";

export default function FormModalScheduleMeeting({
  show,
  handleClose,
  handleSubmitMeeting,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  address,
  setAddress,
  error,
  meetingTitle,
  setMeetingTitle,
  estimatedMonthlyBill,
  setEstimatedMonthlyBill,
  meetingDate,
  setmeetingDate,
  meetingTime,
  setMeetingTime,
  description,
  setDescription,
  modalType,
  isFocused,
  setIsFocused
}) {

  useEffect(() => {
    if (show === false) {
      setErrorValidation({});
      setErrorValidationTime({ meetingTime: '' })
    }
  }, [show])


  const [errorValidation, setErrorValidation] = useState({});
  const [errorValidationTime, setErrorValidationTime] = useState({ meetingTime: '' });

  let regChar = /^[a-zA-Z]*$/;
  let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const validateForm = () => {
    const newErrors = {};
    const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
    const cleanedPhonePattern = /\D/g;


    if (!firstName) {
      newErrors.firstName = "First Name is required!";
    } else if (firstName.length < 3) {
      newErrors.firstName = "First name should be greater than 3 characters.";
    } else if (!regChar.test(firstName)) {
      newErrors.firstName = "First Name accept only character!";
    } else if (firstName.length >= 15) {
      newErrors.firstName = "First name should be shorter than 15 characters.";
    }

    if (!lastName) {
      newErrors.lastName = "Last Name is required!";
    } else if (lastName.length < 3) {
      newErrors.lastName = "Last name should be greater than 3 characters.";
    } else if (!regChar.test(lastName)) {
      newErrors.lastName = "Last Name accept only character!";
    } else if (lastName.length >= 15) {
      newErrors.lastName = "Last name should be shorter than 15 characters.";
    }

    if (!email) {
      newErrors.email = "Email is required!"
    } else if (!regEmail.test(email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!phone) {
      newErrors.phone = "Phone is required!"
    } else if (phone.replace(cleanedPhonePattern, '').length !== 10) {
      newErrors.phone = "Phone number must be exactly 10 digits!";
    }

    if (!address) newErrors.address = "Address is required!";


    if (!meetingTitle) newErrors.meetingTitle = "Meeting Title is required!";
    if (!meetingTime) newErrors.meetingTime = "Meeting Time is required!";
    // if (!meetingDate) newErrors.meetingDate = "Meeting Date is required!";

    console.log(meetingDate, 'meetingDate');

    if (!meetingDate) {
      newErrors.meetingDate = "Meeting Date is required!";
    } else {
      const datePattern = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

      console.log(datePattern.test(meetingDate), 'datePattern.test(meetingDate)');

      if (!datePattern.test(meetingDate)) {
        newErrors.meetingDate = "Meeting Date must be in the format MM/DD/YYYY!";
      }
    }


    if (!description) newErrors.description = "Description is required!";

    if (!estimatedMonthlyBill) newErrors.estimatedMonthlyBill = "Estimated Monthly Bill is required!";


    setErrorValidation(newErrors);
    setErrorValidationTime(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const formatPhone = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
    const match = cleanedValue.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (cleanedValue.length !== 10) {
      const newErrors = {};
      newErrors.phone = "Phone number must be exactly 10 digits!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else if (match) {
      const newErrors = {};
      newErrors.phone = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhone(e.target.value);
    setPhone(formattedPhone);
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    const isvalid = validateForm();
    if (isvalid) {
      handleSubmitMeeting();
      handleClose();
      setMeetingTime('')
      setDescription('')
      setmeetingDate('')
      setMeetingTitle('')
      setEstimatedMonthlyBill('')
    }
  };

  const handleFirstNameChange = (e) => {
    const firstNameInput = e.target.value;
    if (firstNameInput === '') {
      const newErrors = {};
      newErrors.firstName = "First Name is required!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else if (regChar.test(firstNameInput)) {
      setFirstName(firstNameInput);
      const newErrors = {};
      newErrors.firstName = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else {
      const newErrors = {};
      newErrors.firstName = "Only alphabetic characters are allowed.";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  }

  const handleLastNameChange = (e) => {
    const lastNameInput = e.target.value;
    if (lastNameInput === '') {
      const newErrors = {};
      newErrors.lastName = "Last Name is required!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else if (regChar.test(lastNameInput)) {
      setLastName(lastNameInput);
      const newErrors = {};
      newErrors.lastName = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else {
      const newErrors = {};
      newErrors.lastName = "Only alphabetic characters are allowed.";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  }

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    if (emailInput === '') {
      const newErrors = {};
      newErrors.email = "Email is required!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else if (regEmail.test(emailInput)) {
      setEmail(emailInput);
      setErrorValidation((prev) => ({ ...prev, email: '' }));
    } else {
      const newErrors = {};
      newErrors.email = "Please enter a valid email address.";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  };

  const handleAddressChange = (e) => {
    const addressInput = e.target.value;
    if (addressInput?.trim() !== '') {
      const newErrors = {};
      newErrors.address = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else {
      const newErrors = {};
      newErrors.address = "Address is required!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  };

  const handleMeetingTitleChange = (e) => {
    const meetingTitleInput = e.target.value;
    if (meetingTitleInput.trim() !== '') {
      setErrorValidation((prev) => ({ ...prev, meetingTitle: "" }));
    } else {
      setErrorValidation((prev) => ({ ...prev, meetingTitle: "Meeting Title is required!" }));
    }
    setMeetingTitle(meetingTitleInput); // Ensure state is updated here
  };

  const handleEstimateMonthlyBillChange = (e) => {
    const estimatedMonthlyBillInput = e.target.value;
    // Remove the dollar sign and validate 
    const numericValue = estimatedMonthlyBillInput.replace(/[$,]/g, '');
    console.log(numericValue, 'numericValue');
    if (numericValue.trim() !== '' && !isNaN(numericValue)) {
      const newErrors = {};
      newErrors.estimatedMonthlyBillInput = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else {
      const newErrors = {};
      newErrors.estimatedMonthlyBillInput = "Estimated Monthly Bill is required and must be a valid number!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  };

  const handleChangeEstimateBill = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9.]/g, '');
    setEstimatedMonthlyBill(numericValue ? `$${numericValue}` : "");
    handleEstimateMonthlyBillChange({ target: { value: numericValue } });
  };

  const handleFocusEstimateBill = () => {
    if (!isFocused) {
      setEstimatedMonthlyBill((prev) => (prev ? prev : "$")); // Set to $0 if empty
      setIsFocused(true);
    }
  };

  const handleBlurEstimateBill = () => {
    setIsFocused(false);
    if (estimatedMonthlyBill === "$") {
      setEstimatedMonthlyBill("");
    }
  };

  const handleMeetingDateChange = (date) => {
    if (date) {
      const month = date.getMonth() + 1; // Months are 0-indexed
      const day = date.getDate();
      const year = date.getFullYear();

      // Format date as MM-DD-YYYY
      // const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

      setErrorValidation({ ...errorValidation, meetingDate: '' });
      setmeetingDate(formattedDate); // Update the meeting date state with formatted date
    } else {
      setErrorValidation({ ...errorValidation, meetingDate: 'Invalid date format. Use MM-DD-YYYY.' });
    }
  };


  const handleDescriptionChange = (e) => {
    const meetingDescriptionInput = e.target.value;
    if (meetingDescriptionInput?.trim() !== '') {
      const newErrors = {};
      newErrors.meetingDescriptionInput = "";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    } else {
      const newErrors = {};
      newErrors.meetingDescriptionInput = "Description is required!";
      setErrorValidation((prev) => ({ ...prev, ...newErrors }));
    }
  };

  const handleMeetingTimeChange = (time) => {
    const timeFormat = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

    // Validate the time format (HH:MM:SS)
    if (!timeFormat.test(time)) {
      setErrorValidationTime({ ...errorValidationTime, meetingTime: 'Invalid time format. Use HH:MM:SS.' });
    } else {
      setErrorValidationTime({ ...errorValidationTime, meetingTime: '' }); // Corrected to use errorValidationTime
    }
  };

  const formatTime = (time) => {
    if (!time) return ''; // Guard clause if time is null or undefined
    const hours = String(time.getHours()).padStart(2, '0');
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  return (

    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton >
        <Modal.Title>Schedule a Meeting</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form onSubmit={handleSubmitData}>
          <Form.Group controlId="formFirstName">
            <Form.Label className="fw-bold">
              First Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              value={firstName}
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              title="Space not allowed!"
              onKeyUp={handleFirstNameChange}
            // pattern="[^ ]*"
            // onChange={(e) => handleOnChange(e)}
            // onBlur={(e) => handleOnBlur(e)}
            // maxLength={15}
            // minLength={3}
            // required
            />
            {errorValidation.firstName && <span className="error-validation">{errorValidation.firstName}</span>}
          </Form.Group>

          <Form.Group controlId="formLastName">
            <Form.Label className="fw-bold mt-3">
              Last Name <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              title="Space not allowed!"
              onKeyUp={handleLastNameChange}
            // pattern="[^ ]*"
            // maxLength={15}
            // minLength={3}
            // required
            />
            {errorValidation.lastName && <span className="error-validation">{errorValidation.lastName}</span>}
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label className="fw-bold mt-3">
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={255}
              onKeyUp={handleEmailChange}
            // pattern="[^ ]*"
            // required
            />
            {errorValidation.email && <span className="error-validation">{errorValidation.email}</span>}
          </Form.Group>

          <Form.Group controlId="formPhoneNumber">
            <Form.Label className="fw-bold mt-3">Phone Number <span className="text-danger">*</span></Form.Label>
            <div className="input-group">
              <div className="input-group-prepend"> <span className="input-group-text rounded-0 p-2"> <img src={usFlag} alt="usa-flag" className="us-flag-image" /> +1
              </span> </div>
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                value={phone}
                name="phone"
                onChange={(e) => setPhone(e.target.value)}
                onKeyUp={handlePhoneChange}
                title="Required only number"
                maxLength={14}
              // pattern="\d{10}"
              // pattern="^[0-9]*$"
              // minLength={6}
              />
            </div>
            {errorValidation.phone && <span className="error-validation">{errorValidation.phone}</span>}
          </Form.Group>

          <Form.Group controlId="formAddress">
            <Form.Label className="fw-bold mt-3">Address <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              value={address}
              name="address"
              onChange={(e) => setAddress(e.target.value)}
              onKeyUp={handleAddressChange}
              maxLength={255}
              pattern="^[^\s].*"
            />
            {errorValidation.address && <span className="error-validation">{errorValidation.address}</span>}
          </Form.Group>

          <Form.Group controlId="formMeetingTitle">
            <Form.Label className="fw-bold mt-3">Meeting Title <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Meeting Title"
              value={meetingTitle}
              name="meetingTitle"
              onChange={(e) => setMeetingTitle(e.target.value)}
              onKeyUp={handleMeetingTitleChange}
              maxLength={255}
              pattern="^[^\s].*"
            />
            {errorValidation.meetingTitle && <span className="error-validation">{errorValidation.meetingTitle}</span>}
          </Form.Group>

          <Form.Group controlId="formEstimatedMonthlyBill">
            <Form.Label className="fw-bold mt-3">
              Estimated Monthly Bill
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control type="text" // Keep it as text to allow dollar sign 
              placeholder="Enter Amount"
              value={estimatedMonthlyBill}
              name="estimatedMonthlyBill"
              onChange={handleChangeEstimateBill}
              onFocus={handleFocusEstimateBill} // Add dollar sign on focus 
              onBlur={handleBlurEstimateBill} // Optional: Handle blur for resetting  
            />
            {errorValidation.estimatedMonthlyBill && (
              <span className="error-validation">{errorValidation.estimatedMonthlyBill}</span>
            )}
          </Form.Group>

          <Form.Group controlId="formMeetingDate" >
            <Form.Label className="fw-bold mt-3 datepicker-label d-block">
              Meeting Date <span className="text-danger">*</span>
            </Form.Label>

            <DatePicker
              selected={meetingDate}
              onChange={handleMeetingDateChange}
              placeholderText="Select Date"
              dateFormat="MM/dd/yyyy" // You can leave this as is to keep validation consistent
              className="form-control_date-picker w-100"
            />

            {errorValidation.meetingDate && (
              <span className="error-validation">{errorValidation.meetingDate}</span>
            )}
          </Form.Group>

          <Form.Group controlId="formMeetingTime">
            <Form.Label className="fw-bold mt-3 datepicker-label d-block">
              Meeting Time <span className="text-danger">*</span>
            </Form.Label>

            <DatePicker
              selected={meetingTime ? new Date(`1970-01-01T${meetingTime}`) : null} // Convert formatted time back to Date object for DatePicker
              className='form-control_time-picker'
              onChange={(date) => {
                console.log('DatePicker onChange triggered'); // Debugging statement
                console.log('Selected date:', date); // Log the selected date

                // Format the time from the selected Date object
                const formattedTime = formatTime(date);
                console.log('Formatted time:', formattedTime); // Log the formatted time

                // Update meetingTime state with the formatted time string
                setMeetingTime(formattedTime);
                console.log(formattedTime, 'setMeetingTime(formattedTime)'); // Log the formatted time

                // Pass formatted time to handleMeetingTimeChange for validation
                handleMeetingTimeChange(formattedTime);
                console.log(formattedTime, 'handleMeetingTimeChange(formattedTime)');
              }}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              // timeFormat='HH:mm'
              // dateFormat="HH:mm:ss a"
              dateFormat="h:mm:ss aa"
              showDateSelect={false} // Ensure only time is selected
              placeholderText="Select Time"

            />

            {errorValidationTime.meetingTime && (
              <span className="error-validation">{errorValidationTime.meetingTime}</span>
            )}
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label className="fw-bold mt-3">Description<span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Description"
              value={description}
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              onKeyUp={handleDescriptionChange}
              maxLength={500}
              pattern="^[^\s].*"
            />
            {errorValidation.description && <span className="error-validation">{errorValidation.description}</span>}
          </Form.Group>

          <Button variant="primary" type="submit" className="mt-3 border-0" style={{ backgroundColor: '#189ad3' }}>
            Submit
          </Button>
          {error && (<p style={{ color: 'red', marginTop: "10px" }}>{error}</p>)}
        </Form>
      </Modal.Body>
    </Modal >
  );
}